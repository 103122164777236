import React, { Component } from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import './scss/style.scss';
import {ToastContainer} from "react-toastify";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Home = React.lazy(() => import('./views/pages/home/Home'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const Privacidade = React.lazy(() => import('./views/pages/register/Privacidade'));
const Privacidade1 = React.lazy(() => import('./views/pages/register/Privacidade'));

class App extends Component {

  render() {
    return (
      <BrowserRouter>

          <React.Suspense fallback={loading}>
            <ToastContainer
                style={{zIndex:9999999999999}}
                position="top-right"
                autoClose={3000}/>

            <Switch>
              <Route exact path="/pagaiou" name="Pagaiou" render={props => <Home {...props}/>} />
              <Route exact path="/registro" name="Registro" render={props => <Register {...props}/>} />
              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <Route exact path="/v1/doc_pagaiou/POLITICA%20DE%20PRIVACIDADE.pdf" name="Privacidade" render={props => <Privacidade {...props}/>} />
              <Route exact path="/termos-privacidade" name="termos" render={props => <Privacidade1 {...props}/>} />
              <Route exact path="/privacidade" name="privacidades" render={props => <Privacidade1 {...props}/>} />
              <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />
            </Switch>
          </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
